import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import { getSrc, getImage } from 'gatsby-plugin-image';
import get from 'lodash.get';
import { FormattedMessage } from 'react-intl';
import {
  GatsbySeo,
  BreadcrumbJsonLd,
  ProductJsonLd,
} from 'gatsby-plugin-next-seo';

import Layout from 'components/Layout';
import {
  ProductGallery,
  ProductForm,
  ProductDescription,
  RelatedProducts,
} from 'components/Product';
import useSiteMetadata from 'hooks/useSiteMetadata';
import trackEvent from 'utils/trackEvent';

import * as styles from './product.module.css';

export const query = graphql`
  query ProductQuery($collection: String!, $handle: String!, $tags: [String!]) {
    shopifyCollection(handle: { eq: $collection }) {
      id
      title
      handle
    }

    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      description
      descriptionHtml
      shopifyId
      storefrontId
      vendor
      tags
      media {
        id
        shopifyId
        preview {
          image {
            gatsbyImageData(width: 2600)
          }
        }
      }
      options {
        shopifyId
        name
        values
      }
      variants {
        shopifyId
        storefrontId
        id
        title
        price
        compareAtPrice
        availableForSale
        weightUnit
        weight
        selectedOptions {
          name
          value
        }
        media {
          shopifyId
        }
      }
      priceRangeV2 {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
    }

    allShopifyRelatedProduct: allShopifyProduct(
      filter: { tags: { in: $tags }, handle: { ne: $handle } }
      limit: 4
    ) {
      edges {
        node {
          id
          handle
          title
          tags
          featuredMedia {
            id
            preview {
              image {
                altText
                gatsbyImageData(aspectRatio: 1, width: 400)
              }
            }
          }
          collections {
            id
            handle
          }
          variants {
            id
            shopifyId
            price
            compareAtPrice
            availableForSale
          }
          priceRangeV2 {
            minVariantPrice {
              amount
              currencyCode
            }
            maxVariantPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
`;

export default function Product({ data, location }) {
  const { siteUrl } = useSiteMetadata();

  const collection = get(data, 'shopifyCollection');
  const product = get(data, 'shopifyProduct');
  const related = get(data, 'allShopifyRelatedProduct.edges', []);

  if (!product) return null;

  const [variant, setVariant] = useState(null);

  const ogImages = product.media
    .filter((i) => !!i)
    .map((i) => {
      const { width, height } = getImage(i.preview.image);
      const url = getSrc(i);
      return { url, width, height, alt: product.title };
    });

  const canonical = `${siteUrl}/collections/${collection.handle}/products/${product.handle}/`;

  useEffect(() => {
    trackEvent('detail', { product, variant });
  }, [variant]);

  return (
    <Layout location={location}>
      <GatsbySeo
        title={product.title}
        description={product.description}
        canonical={canonical}
        metaTags={[{ name: 'keywords', content: product.tags }]}
        openGraph={{
          url: canonical,
          title: product.title,
          description: product.description,
          images: ogImages,
          type: 'product',
        }}
      />

      <BreadcrumbJsonLd
        name="Product"
        itemListElements={[
          {
            position: 1,
            name: collection.title,
            item: `${siteUrl}/collections/${collection.handle}/`,
          },
          {
            position: 2,
            name: product.title,
            item: `${siteUrl}/collections/${collection.handle}/products/${product.handle}/`,
          },
        ]}
      />

      <ProductJsonLd
        name={product.title}
        description={product.description}
        url={canonical}
        images={product.media.map((i) => getSrc(i.preview.image))}
        brand={product.vendor}
        offersType="AggregateOffer"
        offers={{
          lowPrice: product.priceRangeV2.minVariantPrice.amount,
          priceCurrency: product.priceRangeV2.minVariantPrice.currencyCode,
          highPrice: product.priceRangeV2.maxVariantPrice.amount,
          offerCount: product.variants.length,
        }}
      />

      <div className={styles.container}>
        <div className={styles.hero}>
          <div className={styles.slider}>
            <ProductGallery
              data={{
                images: product.media.map((i) => ({
                  id: i.id,
                  shopifyId: i.shopifyId,
                  ...i.preview.image,
                })),
              }}
              selectedItemId={variant?.media[0]?.shopifyId}
            />
          </div>

          <aside className={styles.aside}>
            <ProductForm product={product} onVariantChange={setVariant} />
            <ProductDescription html={product.descriptionHtml} />
          </aside>
        </div>

        <Link
          to={`/collections/${collection.handle}/`}
          className={styles.backToLink}
        >
          ←{' '}
          <FormattedMessage
            id="products.product.back_to_collection"
            values={{ title: collection.title }}
          />
        </Link>

        <RelatedProducts products={related} />
      </div>
    </Layout>
  );
}
